.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  font-family: 'MedievalSharp';
  font-size: 20px;
  background-image: url('./assets/fantasy-background.png');
  background-size: repeat;
  width: 100% - 30px;
  height: 100%;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

a {
  color: white;
}

.phaseList {
  width: 100%;
  max-width: 700px;
  margin: auto;
  margin-top: 100px;
}

.phaseList>li {
  text-align: start;
  margin-bottom: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.abilityScoreWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.abilityScoreBlock {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
}

.overB {
  color: red;
}

.navMenu {
  width: 90%;
  margin: auto;
  padding: 15px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-menu-container{
  display: flex;
  gap: 20px;
}

.pages {
  /* background-image: url('./assets/fantasy-background.png');
  background-size: cover; */
  width: 100% - 30px;
  height: 100%;
  /* color: white; */
  padding: 15px 100px 15px 50px;
}

.raceInfoWrapper {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  /* max-width: 800px; */
  margin: 50px auto 20px auto;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
}
.raceInfoBlock{
  width: 70%;
}
.raceInfo {
  display: grid;
  grid-template-columns: 1fr .25fr;
}
.raceInfo>p, .raceInfo>h4{
  margin-top: 0;
  margin-bottom: 15px;
}
.defaultRaceWrapper{
  width: 30%;
}
.defaultRaceImage{
  width: 100%;
}

.creditGrid{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 50px;
}
.creditWrapper{
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  padding: 20px 0 20px 0;
}
.creditWrapper>img{
  height: 200px;
}
.creditWrapper>p{
  font-size: 18px;
}